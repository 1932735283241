import React, { FC } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { t } from "@lingui/macro";
import { Logo } from "@components/ui/Logo";
import { Image } from "@components/ui/Image";
import Preferences from "../Navbar/components/Preferences";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl";
import { ROUTES } from "@root/routes";

interface LinkItem {
  name: string;
  href: string;
  target?: string;
}

interface LinksColumnProps {
  title: string;
  links?: LinkItem[];
}

const LinksColumn: FC<LinksColumnProps> = ({ title, links }) => {
  return (
    <div className="mb-10 md:mb-0">
      <h3 className="font-medium text-lg mb-6 border-b md:border-0 border-gray-300 pb-2">
        {title}
      </h3>
      <ul>
        {links &&
          links.map((item) => (
            <li key={item.name} className="mb-2">
              <Link href={item.href}>
                <a
                  title={t({
                    id: "common.link.title",
                    message: "GoWithGuide - Private Tours & Local Tour Guides",
                  })}
                  className="hover:underline text-gray-600"
                  target={item.target || "_self"}
                >
                  {item.name}
                </a>
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

const Footer = () => {
  const router = useRouter();

  const spaceClasses = {
    "/supplier/[id]": "mb-20 md:mb-0",
    "/supplier/[id]/preview": "mb-20 md:mb-0",
    "/[country]/tour/[id]": "mb-24 md:mb-0",
    "/[country]/tour/[id]/preview": "mb-24 md:mb-0",
  };

  const addSpace = spaceClasses[router.pathname] || "";

  const gowithguide = [
    {
      name: t({ id: "footer.about-us", message: "About us" }),
      href: getAbsoluteUrl(ROUTES.about()),
    },
    {
      name: t({ id: "footer.booking-process", message: "Booking Process" }),
      href: getAbsoluteUrl(ROUTES.bookingProcess()),
    },
    {
      name: t({ id: "footer.blog", message: "Blog" }),
      href: getAbsoluteUrl(ROUTES.blog.index()),
    },
  ];

  const support = [
    {
      name: t({ id: "footer.contact-us", message: "Contact Us" }),
      href: getAbsoluteUrl(ROUTES.contact()),
    },
    {
      name: t({ id: "footer.faq", message: "FAQ" }),
      href: getAbsoluteUrl(ROUTES.faq()),
    },
    {
      name: t({ id: "footer.terms", message: "Terms & Conditions" }),
      href: getAbsoluteUrl(ROUTES.terms()),
    },
    {
      name: t({ id: "footer.privacy", message: "Privacy Policy" }),
      href: getAbsoluteUrl(ROUTES.privacy()),
    },
    {
      name: t({
        id: "footer.cancelation-policy",
        message: "Cancellation Policy",
      }),
      href: getAbsoluteUrl(ROUTES.cancellationPolicy()),
    },
  ];

  const followUs = [
    {
      name: t({ id: "footer.instagram", message: "Instagram" }),
      href: "https://www.instagram.com/gowithguide",
      target: "_blank",
    },
    {
      name: t({ id: "footer.facebook", message: "Facebook" }),
      href: "https://www.facebook.com/gowithguide.tours",
      target: "_blank",
    },
  ];

  const workingWithUs = [
    {
      name: t({ id: "footer.tour-guides", message: "Tour Guide" }),
      href: getAbsoluteUrl(ROUTES.supplierApply.index()),
    },
    {
      name: t({ id: "footer.careers", message: "Careers" }),
      href: "https://travelience.jp/jobs/",
      target: "_blank",
    },
    {
      name: t({ id: "footer.affiliates", message: "Affiliates" }),
      href: getAbsoluteUrl(ROUTES.contact()),
    },
  ];

  return (
    <footer className="border-t bg-gray-100 pb-4 py-8">
      <div className="max-w-6xl mx-auto print:hidden">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-x-4 px-8">
          <LinksColumn
            title={t({ id: "footer.gowithguide", message: "GoWithGuide" })}
            links={gowithguide}
          />
          <LinksColumn
            title={t({ id: "footer.support", message: "Support" })}
            links={support}
          />
          <LinksColumn
            title={t({ id: "footer.follow-us", message: "Follow Us" })}
            links={followUs}
          />
          <LinksColumn
            title={t({
              id: "footer.work-with-us",
              message: "Work With Us",
            })}
            links={workingWithUs}
          />
        </div>
        <div className="flex justify-end lg:justify-end md:justify-center max-md:justify-center mr-4">
          {/* used img instead of Image of next js because we need width and height for seo */}
          <Image
            src="/img/cc-badges-ppmcvdam-optimized.avif"
            alt="Buy now with PayPal & credit card"
            fallback="/img/cc-badges-ppmcvdam.png"
            activeCache={true}
            width={316}
            height={40}
          />
        </div>
        <div className="flex flex-wrap md:flex-nowrap items-center justify-between border-t p-4 pb-0 mt-6">
          <div className="flex items-center mx-auto md:mx-0">
            <Logo full={true} className="mr-4" />{" "}
            <span className="text-gray-600">© {new Date().getFullYear()}</span>
          </div>
          <div
            className={`flex items-center mt-4 md:mt-0 w-full md:w-auto ${addSpace}`}
          >
            <div className="border border-gray-300 hover:border-gray-400 p-2 w-full md:w-auto rounded px-4 text-center">
              <Preferences />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
