import React, { FC, ReactElement } from "react";

interface Props {
  count?: number;
  dot?: boolean;
  children: ReactElement;
}
export const Badge: FC<Props> = (props) => {
  let count = `${props.count}`;

  if (props?.count && props?.count > 9) {
    count = "9+";
  }

  return (
    <div className="flex">
      {props.children}

      {!props.dot && (
        <div
          className={`flex place-items-center justify-center relative -end-1 w-4 h-4 rounded-full text-white text-xs bottom-2 ${
            (props.count || 0) > 0 ? "bg-primary" : ""
          }`}
        >
          {(props.count || 0) > 0 ? `${count}` : ""}
        </div>
      )}

      {props.dot && (
        <span className="place-items-center justify-center w-3 h-3 bg-primary rounded-full flex md:hidden" />
      )}
    </div>
  );
};
