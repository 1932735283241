import React, { FC } from "react";
import Link from "next/link";
import { useAuth } from "@helpers/hooks/useAuth";
import { Trans, t } from "@lingui/macro";
import { CustomerNotification } from "@graphql/types";
import { Badge } from "../../../../../components/ui/Badge";

import { Avatar } from "@components/ui/Avatar";
import { Dropdown } from "@components/ui/Dropdown";
import BloggerDropdown from "../BloggerDropdown";
import AdminDropdown from "../AdminDropdown";

export const UserDropdown: FC<{
  notification?: CustomerNotification;
}> = ({ notification }) => {
  const { auth, logout } = useAuth();

  if (!auth) {
    return null;
  }

  return (
    <div className="flex items-center">
      <Avatar src={auth.picture?.url} size="xs" className="mr-2" />
      <Dropdown title={auth.firstName} direction="bottomRight" minWidth="9rem">
        <Link href="/auth/account">
          <a
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
          >
            <Dropdown.Item>
              <Trans id="navbar.account">Account</Trans>
            </Dropdown.Item>
          </a>
        </Link>
        <Link href="/auth/messages">
          <a
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
          >
            <Dropdown.Item>
              <Badge count={notification?.unReadMessagesCount}>
                <Trans id="navbar.messages">Messages</Trans>
              </Badge>
            </Dropdown.Item>
          </a>
        </Link>
        <Link href="/auth/bookings">
          <a
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
          >
            <Dropdown.Item>
              <Trans id="navbar.bookings">Bookings</Trans>
            </Dropdown.Item>
          </a>
        </Link>
        <Link href="/auth/reviews">
          <a
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
          >
            <Dropdown.Item>
              <Trans id="navbar.reviews">Reviews</Trans>
            </Dropdown.Item>
          </a>
        </Link>
        <Link href="/contact">
          <a
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
          >
            <Dropdown.Item>
              <Trans id="navbar.help">Help</Trans>
            </Dropdown.Item>
          </a>
        </Link>

        {auth.isBlogger && <BloggerDropdown />}

        {auth.isAdmin && <AdminDropdown />}

        <Dropdown.Divider />
        <a
          title={t({
            id: "common.link.title",
            message: "GoWithGuide - Private Tours & Local Tour Guides",
          })}
        >
          <Dropdown.Item onClick={() => logout("", true)}>
            <Trans id="navbar.logout">Logout</Trans>
          </Dropdown.Item>
        </a>
      </Dropdown>
    </div>
  );
};

export default UserDropdown;
