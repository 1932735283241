import React from "react";
import { useAuth } from "@helpers/hooks/useAuth";

import NavbarSupplier from "@components/common/Navbar/NavbarSupplier";
import NavbarUser from "@components/common/Navbar/NavbarUser";
import NavbarGuest from "@components/common/Navbar/NavbarGuest";

const Navbar = () => {
  const { auth } = useAuth();

  if (auth) {
    if (auth.isSupplier) {
      return <NavbarSupplier />;
    }

    return <NavbarUser />;
  }

  return <NavbarGuest />;
};

export default Navbar;
