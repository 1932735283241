import React, { FC } from "react";
import { ENVWarning } from "@components/ui/ENVWarning";
import { useRouter } from "next/router";
import { Wrapper } from "../styles";
import { PreferenceModal } from "@components/common/Navbar/components/Preferences";
import AnnouncementBanner from "@components/common/AnnouncementBanner";
import Navbar from "./Navbar";
import Footer from "@components/common/Footer";

const DefaultLayout: FC = ({ children }) => {
  const router = useRouter();

  // Hide footer for these specific end points

  const showFooter = () => {
    if (router.asPath.startsWith("/auth/supplier/tours/")) {
      return false;
    }
    return true;
  };

  return (
    <Wrapper>
      {/* <GlobalNotice /> */}
      <div style={{ minHeight: "500px" }}>
        <div className="print:hidden">
          {/* Announcement Banner */}
          <ENVWarning />
          <AnnouncementBanner />
          <Navbar />
        </div>
        {router.query.modalPreference && <PreferenceModal />}
        {children}
      </div>
      {showFooter() && <Footer />}
    </Wrapper>
  );
};

export default DefaultLayout;
