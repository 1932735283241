import { useRouter } from "next/router";
import React, { KeyboardEvent, useState } from "react";

const SupportSearch = () => {
  const router = useRouter();
  const [searchText, setSearchText] = useState("");

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && searchText) {
      router.push(`/support/search?q=${encodeURI(searchText)}`);
    }
  };

  return (
    <div className="flex justify-center text-black w-full h-14">
      <div className="flex w-full border-2 rounded-full items-center">
        <svg
          className="w-6 h-6 ml-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.5}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>

        <input
          type="text"
          placeholder="How can we help you?"
          onKeyDown={handleKeyPress}
          onChange={(e) => {
            setSearchText(e.currentTarget.value);
          }}
          className="flex w-full text-black h-full border-0 outline-none rounded-full px-4"
        />
      </div>
    </div>
  );
};

export default SupportSearch;
