import React from "react";
import { Trans, t } from "@lingui/macro";
import Link from "next/link";
import { Dropdown } from "@components/ui/Dropdown";

const BloggerDropdown = () => {
  return (
    <>
      <Link href="/auth/posts">
        <a
          title={t({
            id: "common.link.title",
            message: "GoWithGuide - Private Tours & Local Tour Guides",
          })}
        >
          <Dropdown.Item>
            <Trans id="navbar.blog">Blog</Trans>
          </Dropdown.Item>
        </a>
      </Link>
    </>
  );
};

export default BloggerDropdown;
