import React, { FC } from "react";
import Link from "next/link";
import { useAuth } from "@helpers/hooks/useAuth";
import { Trans, t } from "@lingui/macro";
import { SupplierNotification } from "@graphql/types";
import { Badge } from "../../../../../components/ui/Badge";

import { Avatar } from "../../../../../components/ui/Avatar";
import { Dropdown } from "../../../../../components/ui/Dropdown";
import AdminDropdown from "../AdminDropdown";

export const SupplierDropdown: FC<{
  notification?: SupplierNotification;
}> = ({ notification }) => {
  const { auth, logout } = useAuth();

  if (!auth) {
    return null;
  }

  return (
    <div className="flex items-center">
      <Avatar src={auth.picture?.url} size="xs" className="mr-2" />

      <Dropdown title={auth.firstName} direction="bottomRight" minWidth="9rem">
        <Link href="/auth/messages">
          <a
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
          >
            <Dropdown.Item>
              <Badge count={notification?.unReadMessagesCount}>
                <Trans id="navbar.messages">Messages</Trans>
              </Badge>
            </Dropdown.Item>
          </a>
        </Link>
        <Link href="/auth/supplier/analytics">
          <a
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
          >
            <Dropdown.Item>
              <Trans id="navbar.analytics">Analytics</Trans>
            </Dropdown.Item>
          </a>
        </Link>
        <Link href="/auth/supplier/bookings">
          <a
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
          >
            <Dropdown.Item>
              <Badge count={notification?.paidBookingsCount}>
                <Trans id="navbar.bookings">Bookings</Trans>
              </Badge>
            </Dropdown.Item>
          </a>
        </Link>
        <Link href="/auth/supplier/calendar">
          <a
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
          >
            <Dropdown.Item>
              <Trans id="navbar.calendar">Calendar</Trans>
            </Dropdown.Item>
          </a>
        </Link>
        <Dropdown.Divider />
        <Link href="/auth/account">
          <a
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
          >
            <Dropdown.Item>
              <Trans id="navbar.account">Account</Trans>
            </Dropdown.Item>
          </a>
        </Link>
        <Link href="/auth/posts">
          <a
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
          >
            <Dropdown.Item>
              <Trans id="navbar.blog">Blog</Trans>
            </Dropdown.Item>
          </a>
        </Link>

        <Link href="/auth/payments/month">
          <a
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
          >
            <Dropdown.Item>
              <Trans id="navbar.payments">Payments</Trans>
            </Dropdown.Item>
          </a>
        </Link>
        <Link href="/auth/supplier/reviews">
          <a
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
          >
            <Dropdown.Item>
              <Badge count={notification?.reviewNotRepliedCount}>
                <Trans id="navbar.reviews">Reviews</Trans>
              </Badge>
            </Dropdown.Item>
          </a>
        </Link>
        <Link href="/auth/supplier/tours">
          <a
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
          >
            <Dropdown.Item>
              <Trans id="navbar.tours">Tours</Trans>
            </Dropdown.Item>
          </a>
        </Link>
        <Dropdown.Divider />
        <Link href="/support">
          <a
            title={t({
              id: "common.link.title",
              message: "GoWithGuide - Private Tours & Local Tour Guides",
            })}
          >
            <Dropdown.Item>
              <Trans id="navbar.help">Help</Trans>
            </Dropdown.Item>
          </a>
        </Link>
        {auth.isAdmin && <AdminDropdown />}
        <Dropdown.Divider />
        <a
          title={t({
            id: "common.link.title",
            message: "GoWithGuide - Private Tours & Local Tour Guides",
          })}
        >
          <Dropdown.Item onClick={() => logout("", true)}>
            <Trans id="navbar.logout">Logout</Trans>
          </Dropdown.Item>
        </a>
      </Dropdown>
    </div>
  );
};

export default SupplierDropdown;
